import React, {useContext, useEffect, Fragment} from 'react';
import {Message} from "semantic-ui-react";
import { RouteComponentProps } from 'react-router-dom';
import LoadingComponent from "../LoadingComponent";
import {format, sub} from "date-fns";
import {RootStoreContext} from "../../stores/rootStore";
import {observer} from "mobx-react-lite";
import HalfPageBackgroundLayout from "../../components/layouts/HalfPageBackgroundLayout";
import ButtonLined from "../../components/layouts/ButtonLined";

interface IProps {
  pubId: string,
  consumerId: string
}

const Redeem:React.FC<RouteComponentProps<IProps>> = ({match, history}) => {
  const rootStore = useContext(RootStoreContext);
  const {getPubConsumer, loading, consumer, redeemPint} = rootStore.tradeStore;

  const {consumerId, pubId} = match.params;

  useEffect(() => {
    getPubConsumer(consumerId, pubId);
  },[consumerId, pubId, getPubConsumer]);

  if(loading) return <LoadingComponent />

  return (
    <Fragment>
      {consumer && consumer.fullName &&
        <HalfPageBackgroundLayout pageName='redeem'>
        {consumer && !consumer.redeemed &&
        <Message warning>
            <Message.Header>WARNING: For pub use only</Message.Header>
            <p>This page is for pub use only. Using any of the buttons on this page will prevent you from collecting your
                pint!</p>
        </Message>
        }
  
        {consumer && consumer.redeemed && consumer.redemptionDate <= sub(new Date(), {minutes: 2}) &&
          <Message negative>
          <Message.Header>This pint has already been redeemed</Message.Header>
          <p>It was redeemed on {format(consumer.redemptionDate, 'do MMMM yyyy @ h:mm a')}</p>
          </Message>
        }
  
        {consumer && consumer.redeemed && consumer.redemptionDate > sub(new Date(), {minutes: 2}) &&
          <Message positive>
          <Message.Header>This pint has now been redeemed</Message.Header>
          <p>It was redeemed on {format(consumer.redemptionDate, 'do MMMM yyyy @ h:mm a')}</p>
          </Message>
        }
  
          <h1>{consumer!.fullName}</h1>
            <p>Pub: <strong>{consumer!.pubName}</strong></p>
            <p>Date: Between <strong>{format(consumer!.weekCommencing, 'eeee do MMMM')} - {format(consumer!.weekEnding, 'eeee do MMMM')}</strong> (any time during this period)</p>
            <p>Drink: <strong>{consumer!.drinkType === 'Tennents' ? 'Tennent\'s' : 'Tennent\'s Light'}</strong></p>
  
  
        {consumer && !consumer.redeemed &&
          <div className='bottom-buttons'>
              <ButtonLined onClick={() => redeemPint(consumer.pubId, consumer.consumerId)}>
                  Redeem pint - FOR PUB USE ONLY
              </ButtonLined>
          </div>
          }
        </HalfPageBackgroundLayout>
      }
    </Fragment>
  )
}

export default observer(Redeem);
