import React from 'react';

const MonoHeader = () => {
  return (
    <div className='pageheader mono' style={{display: "none"}}>
      <div></div>
      <div className='logo'></div>
      <div></div>
    </div>
  )
}

export default MonoHeader;
