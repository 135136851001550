import React, {Fragment, useContext} from 'react';
import {Button, Modal} from "semantic-ui-react";
import {RootStoreContext} from "../../stores/rootStore";

const TermsAndConditions = () => {
  const rootStore = useContext(RootStoreContext);
  const {closeModal} = rootStore.commonStore;
  
  return (
    <Fragment>
      <Modal.Header style={{marginTop: 60}}>Terms and Conditions</Modal.Header>
      <Modal.Content>
        <p>Summary Ts &amp; Cs: </p>

        <p>Northern Ireland only. 18+. Opens from 14:00 15<sup>th</sup> September 2020 to 23.59 8<sup>th</sup> November 2020, or until venue has used allocation. Internet access required. No purchase required. Visit <a href="https://tennentsnidedicatedtoyou.com" target="_blank" rel="noopener noreferrer">tennentsnidedicatedtoyou.com</a> and enter name, age, email address and postcode. Choose one of the local participating venues. Once you have confirmed your details, an e-voucher showing your name, the assigned venue, and the promotion dates will be sent to the confirmed email address. The e-voucher is not a booking to a pub, all pubs should be contacted directly if they have further booking policies in place. E-voucher must be shown upon claiming the pint in venue. There are a total of 80 complimentary pints available per venue. There are 219 venues in total. Whilst stocks last. Max one claim and pint per person only. Non-alcoholic alternative drink is available on request. Full terms: <a href="https://tennents.co.uk/terms-and-conditions/" target="_blank" rel="noopener noreferrer">https://tennents.co.uk/terms-and-conditions/</a> </p>

        <p>Full Ts &amp; Cs </p>

        <ol>
          <li>These Terms and Conditions prevail in the event of any conflict or inconsistency with any other communications, including advertising or promotional materials. Entry and claim instructions are deemed to form part of the terms and conditions and by participating, all entrants will be deemed to have accepted and be bound by the terms and conditions. Please retain a copy for your information. </li>
          <li>Promoter: Tennent’s NI, Aghnatrisk Rd, Hillsborough BT26 6JJ </li>
          <li>Eligibility: This promotion is open to Northern Ireland residents aged 18 or over, excluding employees and the immediate families of the Promoter, its subsidiaries, other group companies, its agents, or anyone else professionally connected with this promotion.</li>
          <li>Internet access required. Participants are responsible for their own costs associated with accessing the internet. These costs may vary so please check with your service provider for current charges. No purchase required.</li>
          <li>Promotion Period: The promotion will run from 14:00 15<sup>th</sup> September 2020 to 23.59 8<sup>th</sup> November 2020. Any applications received outside of the Promotion Period will be automatically rejected. When the 80 pints are claimed at a participating venue, the promotion will no longer be available at that venue.</li>
          <li>How to enter: Enter directly at <a href="https://tennentsnidedicatedtoyou.com" target="_blank" rel="noopener noreferrer">tennentsnidedicatedtoyou.com</a>. You will be requested to enter your name, age, email address and postcode. Choose one of the local participating venues. Following this, you will confirm your details. Once confirmed, an e-voucher showing your name, the assigned venue, and the promotion dates will be sent to you. This e-voucher needs to be shown upon claiming the pint in venue. If unused in the stated week the e-voucher will become invalid and cannot be redeemed under any circumstances.</li>
          <li>Entrants who do not give correct details or those who make an entry on someone else’s behalf will be disqualified, at the Promoter’s discretion.</li>
          <li>Incomplete, illegible, invalid, misdirected, or late entries will not be accepted. Proof of sending will not be accepted as proof of delivery. The Promoter takes no responsibility for entries delayed, incomplete or lost due to technical reasons or otherwise.</li>
        </ol>

        <p><strong>Prizes:</strong></p>

        <ol start={9}><li>There are 219 venues and each venue has 80 pints of Tennent’s available. Whilst stocks last. When the 80 pints are claimed at a participating venue, the promotion will no longer be available at that venue.</li>
          <li>Participating venues only. Available venues may vary during the promotion due to opening at different times as a result of Covid-19. The full list of those available will be shown on tennentsnidedicatedtoyou.com and will be updated as venues are opened.</li>
          <li>Max one pint to be claimed per person only. Max one e-voucher available per person.</li>
          <li>Non-alcoholic alternative drink is available on request when you show your e-voucher at the bar.</li>
        </ol>

        <p><strong>Prize Fulfilment:</strong></p>
        <ol start={13}>
          <li>Entrants will be emailed the e-voucher to their confirmed email address. It is the responsibility of entrants to check junk/SPAM to ensure they are in receipt of any communications. If an entrant does not claim their pint, the Promoter reserves the right at its absolute discretion to withdraw the pint allocation and reallocate the pint to another entrant selected using the same methodology as above.</li>
          <li>One e-voucher per person and the e-voucher can only be used once to claim one pint in the chosen venue.</li>
          <li>Photocopies, reprints, damaged, expired, altered or defaced e-vouchers will not be accepted.</li>
          <li>Proof of ID may be requested by the pub to check age and that the name matches the voucher.</li>
          <li>The e-voucher is not a booking to the pub, all pubs should be contacted if they have further booking policies in place.</li>
          <li>The pint cannot be combined with other discounts, cashback activities or promotions. The pint is not refundable and cannot be exchanged for cash or other goods and services in whole or in part.</li>
          <li>Offer not valid in conjunction with any other offers, promotions, or voucher codes. Promotion is not valid in venues that aren’t taking part in this promotion.</li>
          <li>No cash or other alternative gift will be provided in whole or in part, except that in the event of circumstances outside of its control the Promoter reserves the right to substitute any complimentary pint with an item of equal or greater value. The prize is non-transferable. In the event of any dispute, the decision of the Promoter is final, and no correspondence will be entered into.</li>
          <li>If delivery or any element of this Promotion will be delayed or affected due to the continuing Covid-19 pandemic and resulting Government restrictions, all entrants/recipients affected will be contacted by the Promoter and kept updated of any unavoidable changes to the promotional dates and/or revised delivery dates.</li>
        </ol>

        <p><strong>General:</strong></p>
        <ol start={22}>
          <li>Syndicated entries or those made using methods such as a computer macro, a script or the use of automated devices or processes or the use of multiple devices are not allowed, and all such entries will be disqualified. Entries (bulk or otherwise) made from trade, consumer groups or third parties will not be accepted.</li>
          <li>Where an entrant is believed to have exceeded their entry allowance or not complied with these Terms &amp; Conditions or the spirit of the promotion in any other way, all entries received from that individual will be disqualified and they will be refused further participation in the promotion.</li>
          <li>If for any reason any aspect of this promotion is not capable of running as planned, the Promoter may in its sole discretion modify or suspend the promotion or invalidate any affected entries/claims. If an act, omission, event or circumstance occurs which is beyond the reasonable control of the Promoter and which prevents the Promoter from complying with these terms and conditions the Promoter will not be liable for any failure to perform or delay in performing its obligation.</li>
          <li>The Promoter and its associated agencies and companies will not be liable for any loss (including, without limitation, indirect, special or consequential loss or loss of profits), expense or damage which is suffered or sustained (whether or not arising from any person’s negligence) in connection with this promotion or accepting or using the prizes, except for any liability which cannot be excluded by law (including personal injury, death and fraud) in which case that liability is limited to the minimum allowable by law.</li>
          <li>The Promoter has no control over communication networks and is not liable for any problems associated with them due to traffic congestion, technical malfunction or otherwise. The Promoter will not be held liable to any individual for any fraud committed by any third party nor for any event beyond its control including, but not limited to, user error and any network, computer, hardware or software failures of any kind which may restrict, delay or prevent a participant’s entry to the Promotion.</li>
          <li>If any of these clauses should be determined to be illegal, invalid or otherwise unenforceable then it shall be severed and deleted from these terms and conditions and the remaining clauses shall survive and remain in full force and effect.</li>
          <li>These terms and conditions and any related disputes are subject to interpretation under the laws of Northern Ireland, and to the jurisdiction of the courts of Northern Ireland.</li>
          <li>This promotion is administered by Multiply UK, 26 Palmerston Place, Edinburgh, Scotland on behalf of the Promoter.</li>
          <li>The Promoter will only use the personal details supplied for the administration of the promotion and for no other purpose unless we have your consent. Your personal details will at all times be kept confidential and in accordance with current Data Protection legislation. Please visit <a href="https://tennents.co.uk/privacy-policy/" target="_blank" rel="noopener noreferrer">https://tennents.co.uk/privacy-policy/</a> for the Promoter’s Privacy Policy. You can request access to your personal data, or have any inaccuracies rectified, by sending an email to winner@multiplyagency.com. By participating in the Promotion, you agree to the use of your personal data as described here.</li>
          <li>The Promoter encourages responsible drinking and would direct consumers to read <a href="http://www.drinkaware.co.uk/" target="_blank" rel="noreferrer noopener" >http://www.drinkaware.co.uk/</a> for more information and consider the safe drinking levels recommended in the UK Chief Medical Officers’ Low Risk Drinking Guidelines that are available at <a href="https://www.gov.uk/government/publications/alcohol-consumption-advice-on-low-risk-drinking" target="_blank" rel="noopener noreferrer">https://www.gov.uk/government/publications/alcohol-consumption-advice-on-low-risk-drinking</a>.</li>
        </ol>
        
        <Button basic onClick={closeModal}>Close</Button>

      </Modal.Content>
    </Fragment>
  )
}

export default TermsAndConditions;
