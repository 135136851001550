import React from 'react';

const ColourHeader = () => {
  return (
    <div className='pageheader colour'>
      <div className='logo'><img alt="Tennent's Logo, a red letter T with gold outline." src='/assets/tLogo.svg?v=2' /></div>
    </div>
  )
}

export default ColourHeader;
