import React from 'react';

interface IProps {
  onClick: () => void
}

const ButtonSquared:React.FC<IProps> = ({onClick, children}) => {
  return (
    <button className='button squared' onClick={onClick}>{children}</button>
  )
}

export default ButtonSquared;
