import React from 'react';
import FullPageBackgroundLayout from "../../components/layouts/FullPageBackgroundLayout";

const PausedPage:React.FC = () => {

  return (
    <FullPageBackgroundLayout pageName='paused-page'>
      <img src='/assets/dedicated-to-you.png' alt="Tennent\'s. Dedicated to You."
           style={{maxWidth: '100%', marginBottom: 60}} />
      <p>
          We hope you are staying safe and well.
      </p>
      <p>
          With the on-going continued changes in restrictions we have closed our Dedicated To You promotion. Follow our Tennent’s Lager social channels to keep up to date with the latest promotions.
      </p>
    </FullPageBackgroundLayout>
  )
}

export default PausedPage
