import Axios, {AxiosResponse} from "axios";
import {history} from "../..";
import {toast} from "react-toastify";
import {IConsumer} from "../models/consumer";
import {IMonthDto} from "../models/pubSlot";
import {IConfig} from "../models/config";

const responseBody = (response: AxiosResponse) => response.data;

Axios.interceptors.response.use(undefined, error=> {
  if(error.message === 'Network Error' && !error.response) {
    toast.error("Network error - make sure API is running!");
  }
  const {status, headers} = error.response;
  if(status === 404) {
    toast.error("API endpoint not found");
    history.push('/notfound');
  }
  if(status === 401 && headers['www-authenticate'].includes('Bearer error="invalid_token, error_description="The token expired')) {
    window.localStorage.removeItem('jwt');
    history.push('/');
    toast.info('Your session has expired, please login again');
  }
  if(status === 500) {
    toast.error('Server error');
  }
  throw error.response;
})

const requests = {
  // ****************************************
  get: (url: string) => Axios.get(url).then(responseBody),
  post: (url: string, body: {}) => Axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => Axios.put(url, body).then(responseBody),
  del: (url: string) => Axios.delete(url).then(responseBody),
  postForm: (url: string, file: Blob) => {
    let formData = new FormData();
    formData.append('File', file);
    return Axios.post(url, formData, {
      headers: {'Content-type': 'multipart/form-data'}
    }).then(responseBody);
  }
}

const Common = {
  getConfig: () : Promise<IConfig> => requests.get('/config.json')
}

const Consumer = {
  checkIp: () => requests.get(`consumer/checkip`),
  checkEmail: (emailAddress: string) => requests.get(`consumer/checkemail?emailaddress=${emailAddress}`),
  checkPostcode: (postcode:string) => requests.get(`consumer/checkpostcode/${postcode}`),
  saveConsumer: (consumer: IConsumer) => requests.post(`consumer`, consumer),
  onlineEmail: (consumerId: string) => requests.get(`consumer/onlineemail/${consumerId}`)
}

const Pub = {
  getPubs: (longitude: number, latitude: number, maxPubs: number) => requests.get(`pub/list/${longitude}/${latitude}/${maxPubs}`),
  getDates: (pubId: string) : Promise<IMonthDto[]> => requests.get(`pub/${pubId}/slots`)
}

const Trade = {
  checkConsumer: (pubId: string, consumerId: string) => requests.get(`trade/${pubId}/consumer/${consumerId}`),
  redeem: (pubId: string, consumerId: string) => requests.put(`trade/${pubId}/consumer/${consumerId}/redeem`, {}),
}

export default {
  Common, Consumer, Pub, Trade
}
