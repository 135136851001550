import {RootStore} from "./rootStore";
import {action, observable, runInAction} from "mobx";
import agent from "../api/agent";
import {IPub} from "../models/pub";
import {toast} from "react-toastify";
import {IMonthDto} from "../models/pubSlot";


export interface ILocation {
  latitude: number;
  longitude: number;
}

export default class PubStore {
  rootStore: RootStore;
  
  constructor(rootStore:RootStore) {
    this.rootStore = rootStore;
  }

  @observable pubs:IPub[] = [];
  @observable defaultMapPosition: ILocation | null = null;
  @observable pubMapPosition:ILocation | null = null;
  @observable loadingPubs = true;
  @observable loadingPubSlots = true;
  
  @observable pubSlotMonths:IMonthDto[] = [];
  
  @action setPubMapPosition = (mapPosition: ILocation | null) => {
    this.pubMapPosition = mapPosition;
  }

  @action getPubs = async (longitude: number, latitude: number) => {
    this.loadingPubs = true;

    this.defaultMapPosition = {longitude: longitude, latitude: latitude};
    
    try {
      const pubs = await agent.Pub.getPubs(longitude,latitude,this.rootStore.consumerStore.maxPubs);
      runInAction(() => {
        this.pubs = pubs;
        this.loadingPubs = false;
      })
    } catch(e) {
      toast.error("Problem loading pubs");
      runInAction(() => {
        this.loadingPubs = false;
      })
    }
  }
  
  @action getPubSlots = async (pubId: string) => {
    this.loadingPubSlots = true;
    
    try {
      const pubSlotMonths = await agent.Pub.getDates(pubId);
      
      pubSlotMonths.forEach((month) => {
        month.monthDate = this.rootStore.commonStore.convertDate(month.monthDate  as any);
        month.weeks.forEach((week) => {
          week.weekDate = this.rootStore.commonStore.convertDate(week.weekDate as any);
          week.weekEnd = this.rootStore.commonStore.convertDate(week.weekEnd as any);
          if(week.tennents) {
            week.tennents.weekCommencing = this.rootStore.commonStore.convertDate(week.tennents.weekCommencing as any);
            week.tennents.weekEnding = this.rootStore.commonStore.convertDate(week.tennents.weekEnding as any);
            this.rootStore.consumerStore.chooseDateSlot(week.tennents, 'Tennents');
          }
          if(week.tennentsLight) {
            week.tennentsLight.weekCommencing = this.rootStore.commonStore.convertDate(week.tennentsLight.weekCommencing as any);
            week.tennentsLight.weekEnding = this.rootStore.commonStore.convertDate(week.tennentsLight.weekEnding as any);
          }
        })
      })
      
      
      runInAction(() => {
        this.pubSlotMonths = pubSlotMonths;
        this.loadingPubSlots = false;
      });
    } catch (e) {
      toast.error("Problem loading date slots");
      runInAction(() => {
        this.loadingPubSlots = false;
      })
    }
  }
  
  @action clearPubSlots = () => {
    this.pubSlotMonths = [];
  }
} 
