import {RootStore} from "./rootStore";
import {action, observable, runInAction} from "mobx";
import {IConfig} from "../models/config";
import Axios from "axios";
import agent from "../api/agent";
import {toast} from "react-toastify";

export default class CommonStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable appLoaded = false;
  @observable config: IConfig | null = null;
  
  @observable showModal = false;
  
  @action openModal = () => {
    this.showModal = true;
  }
  @action closeModal = () => {
    this.showModal = false;
  }


  @action convertDate = (date: string) => {
    const dateSplit = date.split(/[^0-9]/);
    return new Date (parseInt(dateSplit[0]),parseInt(dateSplit[1])-1,parseInt(dateSplit[2]),parseInt(dateSplit[3]),parseInt(dateSplit[4]),parseInt(dateSplit[5]) );
  }

  // Load the configuration file and only then set the app to loaded 
  @action setAppLoaded = async () => {
    try {
      const config = await agent.Common.getConfig();
      runInAction(() => {
        this.config = config;
        this.appLoaded = true;

        Axios.defaults.baseURL =  config.API_URL;
      })
    } catch(e) {
      toast.error("Error loading config");
      runInAction(() => {
        this.appLoaded = false;
      });
    }
  }
}
