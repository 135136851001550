import {RootStore} from "./rootStore";
import {action, observable, runInAction} from "mobx";
import {IPubConsumer} from "../models/pubConsumer";
import agent from "../api/agent";
import {toast} from "react-toastify";

export default class TradeStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable loading = true;
  @observable consumer: IPubConsumer | null = null;
  @observable confirmConsumer = false;
  
  @action openConfirmConsumer = (consumer:IPubConsumer) => {
    this.consumer = consumer;
    this.confirmConsumer = true;
  }

  @action closeConfirmConsumer = () => {
    this.confirmConsumer = false;
  }
  
  @action getPubConsumer = async (consumerId: string, pubId: string) => {
    this.loading = true;

    try {
      const consumer: IPubConsumer = await agent.Trade.checkConsumer(pubId, consumerId);
            
      consumer.weekCommencing = this.rootStore.commonStore.convertDate(consumer.weekCommencing as any);
      consumer.weekEnding = this.rootStore.commonStore.convertDate(consumer.weekEnding as any);
      if (consumer.redeemed) {
        consumer.redemptionDate = this.rootStore.commonStore.convertDate(consumer.redemptionDate as any); 
      }
      runInAction(() => {
        this.consumer = consumer;
        this.loading = false;
      });
    } catch(e) {
      toast.error("Problem loading online email");
      runInAction(() => {
        this.loading = false;
      })
    }
  }

  @action redeemPint = async (pubId: string, consumerId: string) => {
    
    try {
      await agent.Trade.redeem(pubId, consumerId)
      runInAction(() => {
        if(this.consumer !== null) {
          this.consumer.redeemed = true;
          this.consumer.redemptionDate = new Date();
        }
      });
    } catch {
      toast.error("Problem redeeming voucher");
    }
  }
}
  
  

