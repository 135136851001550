import {RootStore} from "./rootStore";
import {action, observable, runInAction} from "mobx";
import {IConsumer} from "../models/consumer";
import agent from '../api/agent';
import {persist} from "mobx-persist";
import {IPub} from "../models/pub";
import {IPubSlot} from "../models/pubSlot";
import {ILocation} from "./pubStore";

export default class ConsumerStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @persist('object') @observable consumer: IConsumer = {
    consumerId: "",
    emailAddress: "",
    fullName: "",
    postcode: "",
    longitude: 0,
    latitude: 0,
    redeemed: false,
    dateOfBirth: null,
    agreedTermsAndConditions: false,
    wantsMarketingCommunications: false,
    day: null,
    month: null,
    year: null,
    dateSlotId: "",
    drinkType: ""
  };

  @persist @observable over18 : boolean | null = null;
  @persist('object') @observable postcodeLocation: ILocation | null = null;
  @persist('object') @observable chosenPub: IPub | null = null;
  @persist('object') @observable chosenDateSlot: IPubSlot | null = null;
  @persist @observable chosenDrink: string = "";
  @persist @observable useLocation = false;
  @observable savingDetails = false;
  @persist @observable maxPubs: number = 15;
  @persist @observable badEmail: string = "";

  @action setBadEmail = (status: string) => {
    this.badEmail = status;
  }
  
  @action setMaxPubs = (maxPubs: number) => {
    this.maxPubs = maxPubs;
  }

  @action setOver18 = (over18: boolean) => {
    this.over18 = over18;
  }
  
  @action setPub = (pub: IPub) => {
    this.chosenPub = pub;
    this.chosenDateSlot = null;
  }

  @action chooseDateSlot = (dateSlot: IPubSlot, drink: string) => {
    this.consumer.dateSlotId = dateSlot.dateSlotId;
    this.consumer.drinkType = drink;
    this.chosenDateSlot = dateSlot;
  }
  
  
  @action checkIp = async () => {
    try {
      await agent.Consumer.checkIp();
    } catch (e) {
      throw e;
    }
  }
  
  @action setDateOfBirth = (day: number, month: number, year: number) => {
    this.consumer.dateOfBirth = new Date(year, month-1, day);
    this.consumer.day = day;
    this.consumer.month = month;
    this.consumer.year = year;
  }
  
  @action setConsumerFromForm = (values: any) => {
    this.consumer.fullName = values.fullName;
    this.consumer.postcode = values.postcode;
    this.consumer.emailAddress = values.emailAddress;
    this.consumer.agreedTermsAndConditions = values.termsAndConditions;
    this.consumer.wantsMarketingCommunications = values.wantsMarketingCommunications;
    this.setDateOfBirth(values.day, values.month, values.year);
    this.setBadEmail("");
  }
  
  @action checkEmailPostcode = async (emailAddress:string, postcode: string) => {
    try {
      await agent.Consumer.checkEmail(emailAddress);
      const postcodeDto = await agent.Consumer.checkPostcode(postcode);
      runInAction(() => {
        if(!this.useLocation) {
          this.consumer.longitude = postcodeDto.longitude;
          this.consumer.latitude = postcodeDto.latitude;
        }
        this.postcodeLocation = {
          latitude: postcodeDto.latitude,
          longitude: postcodeDto.longitude
        }
      });
    } catch(e) {
      throw e;
    }
  }
  
  setNewLocation = async (longitude: number, latitude: number) => {
    this.consumer.longitude = longitude;
    this.consumer.latitude = latitude;

    this.chosenPub = null;
    this.chosenDateSlot = null;
    this.consumer.drinkType = "";
    this.consumer.dateSlotId = "";

    await this.rootStore.pubStore.getPubs(longitude, latitude);
  }
  
  @action setUseLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      runInAction(() => {
        this.useLocation = true;
        this.setNewLocation(position.coords.longitude, position.coords.latitude);
      })
    },
      (e) => {
        alert("Error getting geoposition." + e.message);
      }
      ,{maximumAge: 0, timeout: 1000, enableHighAccuracy:true}
      );
  }

  @action setUsePostcode = () => {
    if(this.postcodeLocation) {
      this.useLocation = false;

      this.setNewLocation(this.postcodeLocation.longitude, this.postcodeLocation.latitude);
    }
  }

  @action storeConsumerDetails = async () => {
    this.savingDetails = true;

    try {
      await agent.Consumer.saveConsumer(this.consumer);
      runInAction(() => {
        this.savingDetails = true;
      });
    } catch (e)
    {
      runInAction(() => {
        this.savingDetails = false;
      });
      throw(e);
    }
  }

  // Blank everything back to init state
  @action clearAll = () => {
    this.chosenPub = null;
    this.chosenDateSlot = null;
    this.chosenDrink = "";
    this.useLocation = false;
    this.postcodeLocation = null;
    this.over18 = false;

    this.consumer = {
      consumerId: "",
      emailAddress: "",
      fullName: "",
      postcode: "",
      longitude: 0,
      latitude: 0,
      redeemed: false,
      dateOfBirth: null,
      agreedTermsAndConditions: false,
      wantsMarketingCommunications: false,
      day: null,
      month: null,
      year: null,
      dateSlotId: "",
      drinkType: ""
    };
    
  }
}