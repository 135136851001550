import React, {ButtonHTMLAttributes} from 'react';

interface IProps extends ButtonHTMLAttributes<any>  {
}

const ButtonLined:React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({children, ...props}) => {
  return (
    <button disabled={props.disabled} className='button lined' {...props}>{children} {props.disabled === true}</button>
  )
}

export default ButtonLined;
