import React from 'react';
import ColourHeader from "../headers/ColourHeader";
import Footer from "../footers/Footer";

interface IProps {
  pageName: string
}

const HalfPageBackgroundLayout:React.FC<IProps> = ({ pageName, children }) => {
  return (
    <div className={`half-page-background ${pageName}`}>
      <ColourHeader />

      <div className='flexcol-split'>
        <div className='flexcol left'>
          {children}
        </div>
        <div className='flexcol right'>
          <div><img className='dedicated-text' src='/assets/dedicated-to-you.png' alt="Tennent\'s. Dedicated to You." /> </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default HalfPageBackgroundLayout;
