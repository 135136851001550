import React, {useContext} from 'react';
import { RouteProps,RouteComponentProps, Route, Redirect } from 'react-router-dom';
import {RootStoreContext} from "../stores/rootStore";
import {observer} from "mobx-react-lite";


interface IProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>>
}

const RouteDateOfBirthRequired: React.FC<IProps> = ({component: Component, ...rest}) => {
  const rootStore = useContext(RootStoreContext);
  const {over18} = rootStore.consumerStore;

  return (
    <Route
      {...rest}
      render={(props) => over18 !== null && over18 === true ? <Component {...props} /> : <Redirect to={'/'} /> }
    />
  )
}

export default observer(RouteDateOfBirthRequired);
