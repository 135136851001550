import React, {useContext} from 'react';
import {Modal} from "semantic-ui-react";
import TermsAndConditions from "../../layout/terms-and-conditions/TermsAndConditions";
import {RootStoreContext} from "../../stores/rootStore";
import {observer} from "mobx-react-lite";

const Footer = () => {
  const rootStore = useContext(RootStoreContext);
  const {showModal, openModal, closeModal} = rootStore.commonStore;
  
  return (
    <div className='footer'>
      <div>
      <a href='https://tennents.co.uk/privacy-policy/' target='_blank' rel="noopener noreferrer">Privacy Policy
      </a> | <Modal closeIcon onClose={closeModal} open={showModal}  trigger={<span onClick={openModal}>Terms &amp; Conditions</span>}><TermsAndConditions/>
    </Modal> | <a href='https://www.drinkaware.co.uk/' target='_blank' rel="noopener noreferrer">Drink Aware
    </a> | <a href='https://tennents.co.uk/contact-us/' target='_blank' rel="noopener noreferrer">Contact Us</a>
    </div>
    </div>
  )
}

export default observer(Footer);
