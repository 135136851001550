import LdaPage from "./lda/LdaPage";
import React, {Fragment, useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import { Route, withRouter, RouteComponentProps, Switch } from 'react-router-dom';
import NotFound from "./NotFound";
import {ToastContainer} from "react-toastify";
import LoadingComponent from "./LoadingComponent";
import {RootStoreContext} from "../stores/rootStore";
import ModalContainer from "./common/ModalContainer";
import ChoosePub from "./choose-pub/ChoosePub";
import AboutPage from "./about/AboutPage";
import NoMoreEntries from "./no-more-entries/NoMoreEntries";
import EnterDetails from "./enter-details/EnterDetails";
import NotQuiteYet from "./not-quite-yet/NotQuiteYet";
import PickADate from "./pick-a-date/PickADate";
import ConfirmDetails from "./confirm-details/ConfirmDetails";
import AlreadySignedUp from "./already-signed-up/AlreadySignedUp";
import Thanks from "./thanks/Thanks";
import Redeem from "./trade/Redeem";
import OnlineEmail from "./online-email/OnlineEmail";
import RouteDateOfBirthRequired from "./RouteDateofBirthRequired";
import Sharing from "./sharing/Sharing";
import TagManager from "react-gtm-module";
import PausedPage from "./paused/Paused";

const App: React.FC<RouteComponentProps> = ({location}) => {
  const rootStore = useContext(RootStoreContext);
  const {appLoaded, config} = rootStore.commonStore;
  
  const [gtmLoaded, setGtmLoaded] = useState(false);

  // Initialise Google Tag Manager first time in
  useEffect(() => {
    if(gtmLoaded || !appLoaded) return;

    if(config && config.GTM_TAG) {
      const tagManagerArgs = {
        gtmId: config.GTM_TAG
      }
      TagManager.initialize(tagManagerArgs);
      setGtmLoaded(true);
    }
  }, [appLoaded, gtmLoaded, setGtmLoaded, config])

  if(!appLoaded) return <LoadingComponent content='Loading app...' />

  return (
    <Fragment>
      <ModalContainer />
      <ToastContainer position={"bottom-right"} />
      <Route exact path='/' component={LdaPage} />

      <Route path={'/(.+)'} render={() => (
        <Fragment>
          <Switch>
            <Route exact path='/lda' component={PausedPage} />
            <RouteDateOfBirthRequired exact path='/about' component={PausedPage} />
            {/*<RouteDateOfBirthRequired exact path='/enter-details' component={EnterDetails} />*/}
            {/*<RouteDateOfBirthRequired exact path='/choose-pub' component={ChoosePub} />*/}
            {/*<RouteDateOfBirthRequired exact path='/dates' component={PickADate} />*/}
            {/*<RouteDateOfBirthRequired exact path='/confirm-details' component={ConfirmDetails} />*/}
            <Route exact path='/not-quite-yet' component={NotQuiteYet} />
            {/*<Route exact path='/already-signed-up' component={AlreadySignedUp} />*/}
            {/*<Route exact path='/no-more-entries' component={NoMoreEntries} />*/}
            {/*<Route exact path='/thanks' component={Thanks} />*/}
            <Route exact path='/consumer/onlineemail/:consumerId' component={OnlineEmail} />
            <Route exact path='/trade/:pubId/consumer/:consumerId/redeem' component={Redeem} />
            {/*<Route exact path='/share' component={Sharing} />*/}
            <Route component={NotFound} />
          </Switch>
        </Fragment>
      )} />
    </Fragment>
  );
};

export default withRouter(observer(App));
