import React from 'react';
import FullPageBackgroundLayout from "../components/layouts/FullPageBackgroundLayout";
import ButtonSquared from "../components/layouts/ButtonSquared";
import {history} from "../../index";

const NotFound = () => {
  return (
    <FullPageBackgroundLayout pageName='about-page'>
      <h1>Oops - we've looked everywhere but couldn't find this.</h1>
      <ButtonSquared onClick={() => history.push('/')}>
        Return to Dashboard page
      </ButtonSquared>
    </FullPageBackgroundLayout>
  );
};

export default NotFound;