import React, {useContext} from 'react';
import {history} from "../../../index";
import {RootStoreContext} from "../../stores/rootStore";
import FullPageBackgroundLayout from "../../components/layouts/FullPageBackgroundLayout";
import ButtonSquared from "../../components/layouts/ButtonSquared";

const LdaPage:React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {setOver18} = rootStore.consumerStore;
  
  const over18 = () => {
    setOver18(true);
    history.push('/about');
  }

  const notOver18 = () => {
    setOver18(false);
    history.push('/not-quite-yet');
  }
  
  return (
    <FullPageBackgroundLayout pageName='lda-page'>
      <p>Before we let you in...</p>
      <h1>Are you over 18 years old?</h1>
      <ButtonSquared onClick={over18}>Yes</ButtonSquared>
      <ButtonSquared onClick={notOver18}>No</ButtonSquared>
    </FullPageBackgroundLayout>
  )
}

export default LdaPage;
