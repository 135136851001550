import React, {useContext, useEffect, useState} from 'react';
import agent from "../../api/agent";
import {RouteComponentProps} from "react-router-dom";
import {Image} from 'semantic-ui-react';
import {format} from "date-fns";
import {IPubConsumer} from "../../models/pubConsumer";
import LoadingComponent from "../LoadingComponent";
import {toast} from "react-toastify";
import {RootStoreContext} from "../../stores/rootStore";

interface IProps {
  consumerId: string
}

const OnlineEmail:React.FC<RouteComponentProps<IProps>> = ({match, history}) => {
  const rootStore = useContext(RootStoreContext);
  const {config} = rootStore.commonStore;
  const consumerId = match.params.consumerId;

  const [loading, setLoading] = useState(true);
  const [consumer, setConsumer] = useState<IPubConsumer | null>(null);
  
  useEffect(() => {
    setLoading(true);
    agent.Consumer.onlineEmail(consumerId).then((response) => {
      const consumer:IPubConsumer = response;
      consumer.weekCommencing = new Date(consumer.weekCommencing);
      consumer.weekEnding = new Date(consumer.weekEnding);
      setConsumer(consumer);
      setLoading(false);
    }).catch(() => {
      toast.error("Problem loading online email");
    })
  },[consumerId, setLoading]);
  
  if(loading || config === null) return <LoadingComponent />
  
  return (
    <div style={{maxWidth: 540, margin: '0 auto'}}>
      <div style={{borderBottom: '2px solid #aaa', paddingLeft: 20, paddingTop: 10}}>
        <img src='/assets/emailT.png' alt='Tennents Logo' />
      </div>
      <div style={{padding: '0 20px'}}>
        <h1 style={{fontSize: 30, textTransform: 'uppercase'}}>A complimentary pint for {consumer!.fullName}</h1>
        <p style={{fontSize: 13}}><strong>Between {format(consumer!.weekCommencing, 'eeee do MMMM')} - {format(consumer!.weekEnding, 'eeee do MMMM')} at {consumer!.pubName}</strong><br />
          It’s our round! Just show this code at the bar to redeem your complimentary pint of {consumer!.drinkType === 'Tennents' ? 'Tennent\'s' : 'Tennent\'s Light'}.</p>
        <p style={{fontSize: 13}}>Please make sure to check venue opening times and whether you need to make a booking with the venue directly.</p>
        <p><Image src={`${config.API_URL}/consumer/qrcode/${consumer!.consumerId}`} /></p>
        <table style={{width:'100%', borderCollapse: 'collapse'}}>
          <tr>
            <td style={{width:'50%', backgroundColor: '#FFC72C', padding: '40px 30px'}}><a style={{color: 'black'}} href={`${config.API_URL.substring(0, config.API_URL.length-4)}/share`}>Share with a friend</a><img alt='An underline' style={{display: 'block', marginTop: 6}} src="/assets/shareLine.png"/></td>
            <td style={{width:'50%', backgroundColor: '#C8112E', padding: '40px 30px'}}><a style={{color: 'white'}} href="http://tennents.com">See More At Tennent's</a><img alt='An underline' style={{display: 'block', marginTop: 6}} src="/assets/seeMoreLine.png"/></td>
          </tr>
        </table>
        <table style={{width:'100%', borderCollapse: 'collapse', background: 'black'}}>
          <tr>
            <td style={{padding: '40px 0', textAlign: 'center'}}><a href="https://twitter.com/TennentsLager"><img src="/assets/logoTwitter.png" alt="Twitter" /></a></td>
            <td style={{padding: '40px 0', textAlign: 'center'}}><a href="https://www.instagram.com/tennentslager"><img src="/assets/logoInstagram.png" alt="Instagram" /></a></td>
            <td style={{padding: '40px 0', textAlign: 'center'}}><a href="https://www.facebook.com/TennentsLager/"><img src="/assets/logoFacebook.png" alt="Facebook" /></a></td>
            <td style={{padding: '40px 0', textAlign: 'center'}}><a href="https://www.tripadvisor.co.uk/Attraction_Review-g186534-d3708256-Reviews-Tennents_Wellpark_Brewery-Glasgow_Scotland.html"><img src="/assets/logoTripAdvisor.png" alt="TripAdvisor" /></a></td>
          </tr>
        </table>
        <img style={{width: '100%'}} src="/assets/donateNow.jpg" alt="Man drinking a pint." />
      </div>
    </div>
  )
}

export default OnlineEmail
