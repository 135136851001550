import React from 'react';
import FullPageBackgroundLayout from "../../components/layouts/FullPageBackgroundLayout";

const NotQuiteYet = () => {
  return (
    <FullPageBackgroundLayout pageName='about-page'>
      <h1>Sorry you must be 18 or over to enter</h1>
    </FullPageBackgroundLayout>
  )
}

export default NotQuiteYet;
