import React from 'react';
import MonoHeader from "../headers/MonoHeader";
import Footer from "../footers/Footer";

interface IProps {
  pageName: string
}

const FullPageBackgroundLayout:React.FC<IProps> = ({ pageName, children }) => {
  return (
    <div className={`full-page-background ${pageName}`}>
      <MonoHeader />
      <div className='flexcol-split vertical-centered'>
        <div className='flexcol left'>
          <div>
            {children}
          </div>
        </div>
        <div className='flexcol right'>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default FullPageBackgroundLayout;
