import {configure, runInAction} from "mobx";
import {createContext} from "react";
import CommonStore from "./commonStore";
import ModalStore from "./modalStore";
import ConsumerStore from "./consumerStore";
import {create} from 'mobx-persist';
import * as Storage from '../components/utils/sessionStorage';
import PubStore from "./pubStore";
import TradeStore from "./tradeStore";

configure({enforceActions: true});

const hydrate = create({
  storage: Storage,
  jsonify: true
})

export class RootStore {
  commonStore: CommonStore;
  modalStore: ModalStore;
  consumerStore: ConsumerStore;
  pubStore: PubStore;
  tradeStore: TradeStore;
  
  constructor() {
    this.commonStore = new CommonStore(this);
    this.modalStore = new ModalStore(this);
    this.consumerStore = new ConsumerStore(this);
    this.pubStore = new PubStore(this);
    this.tradeStore = new TradeStore(this);

    
    hydrate('consumer', this.consumerStore).then(() => {
      this.commonStore.setAppLoaded()
      
      runInAction(() => {
        
        // Fix the date if we hydrate it from the store 
        if(this.consumerStore.chosenDateSlot !== null) {
          this.consumerStore.chosenDateSlot.weekCommencing = this.commonStore.convertDate(this.consumerStore.chosenDateSlot.weekCommencing as any);
          this.consumerStore.chosenDateSlot.weekEnding = this.commonStore.convertDate(this.consumerStore.chosenDateSlot.weekEnding as any);
        }
      })
    });
  }
}

export const RootStoreContext = createContext(new RootStore());
